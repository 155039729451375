import React from "react"

const categories = [
  {
    category: "JavaScript",
    tag: "javascript",
  },
  {
    category: "TypeScript",
    tag: "typescript",
  },
  {
    category: "React",
    tag: "react",
  },
  {
    category: "React Native",
    tag: "react-native",
  },
  {
    category: "Node JS",
    tag: "node",
  },
  {
    category: "IOT",
    tag: "iot",
  },
  {
    category: "Dev Ops",
    tag: "devops",
  },
  {
    category: "Misc",
    tag: "misc",
  },
  {
    category: "Projects",
  },
]
class Header extends React.Component {
  render() {
    return (
      <ul
        className="grid grid-cols-2 items-center sm:grid-cols-4 md:flex flex-row justify-between gap-x-2"
        style={{ listStyle: "none", marginBottom: 0 }}
      >
        {categories.map((category) => {
          return category.category === "Projects" ? (
            <li className="text-center">
              <a
                className="text-black no-underline text-sm bg-white rounded-lg p-2 shadow-md"
                href={`/projects`}
              >
                {category.category}
              </a>
            </li>
          ) : (
            <li className="text-center">
              <a
                className="text-white underline text-sm"
                href={`/category/${category.tag}`}
              >
                {category.category}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }
}

export default Header
