import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import Header from "./Header"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}`
    const projectPath = `${__PATH_PREFIX__}/projects`

    let header
    let isHomepage =
      location.pathname === rootPath || location.pathname === `${rootPath}/`
    let isProjectPage =
      location.pathname === projectPath ||
      location.pathname === `${projectPath}/` ||
      location.pathname.includes("projects/")

    if (isHomepage || isProjectPage) {
      header = (
        <h1
          className="text-4xl sm:text-6xl text-white"
          style={{
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <>
          <h3
            className="text-white"
            style={{
              marginTop: 0,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h3>
          <Header />
        </>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <div
          className={`skew-grad ${
            isHomepage || isProjectPage ? "h-96 md:h-80" : "h-80"
          }`}
        ></div>
        <div
          className={`skew-grad-2 pt-2 ${
            isHomepage || isProjectPage ? "h-96 md:h-80" : "h-80"
          }`}
        ></div>

        <header>{header}</header>
        <main>{children}</main>
        <footer>
          © Jay Gould {new Date().getFullYear()}, Built with love and tequila.
        </footer>
      </div>
    )
  }
}

export default Layout
